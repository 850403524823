import React, { useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import LayoutNew from "../components/layout-new";
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #679898, #f0f5f5);
`

const PageThankYou = () => {

  useEffect(() => {
    setTimeout(() => navigate("/testimonials"), 3000);
  }, []);

  const data = useStaticQuery(graphql`
    query PageThankYouQuery {
      imgHero: file(
        relativePath: { eq: "backgrounds/hero-client-res-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: BLURRED)
        }
      }
    }
  `)

  const imgHero = getImage(data.imgHero.childImageSharp)

  return (
    <LayoutNew>
      <Seo title="Thank You" description="Thank You" />
      <HeroSection imgSrc={imgHero} title="Thank You" />

      <div className="relative">
        <div className="absolute top-0 w-full flex flex-col items-center -mt-16">
          <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 rounded-3xl shadow-lg">
            <h2 className="text-white font-medium text-center text-lg md:text-xl mb-4">
              Your submission has been received.
            </h2>
            <h2 className="text-white font-medium text-center text-lg md:text-xl">
              An SRA representative will get back to you as soon as possible
            </h2>
          </div>
        </div>

        <StyledDiv2 className="py-24">
          <div className="w-11/12 lg:w-10/12 max-w-screen-md mx-auto">
            &nbsp;
          </div>
        </StyledDiv2>
      </div>
    </LayoutNew>
  )
}

export default PageThankYou
